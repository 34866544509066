import { LitElement, css, html } from 'lit';
import { property, customElement } from 'lit/decorators.js';
import { resolveRouterPath } from '../router';

import '@shoelace-style/shoelace/dist/components/card/card.js';
import '@shoelace-style/shoelace/dist/components/button/button.js';

import { styles } from '../styles/shared-styles';

@customElement('app-home')
export class AppHome extends LitElement {

  // For more information on using properties and state in lit
  // check out this link https://lit.dev/docs/components/properties/
  @property() message = 'Språklektioner - Lektioner online i engelska, tyska och franska!';

  static styles = [
    styles,
    css`
    #welcomeBar {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }

    #welcomeCard,
    #infoCard {
      padding: 18px;
      padding-top: 0px;
    }

    sl-card::part(footer) {
      display: flex;
      justify-content: flex-end;
    }

    @media(min-width: 750px) {
      sl-card {
        width: 70vw;
      }
    }


    @media (horizontal-viewport-segments: 2) {
      #welcomeBar {
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
      }

      #welcomeCard {
        margin-right: 64px;
      }
    }
  `];

  async firstUpdated() {
    // this method is a lifecycle even in lit
    // for more info check out the lit docs https://lit.dev/docs/components/lifecycle/
    console.log('This is your home page');
  }

  share() {
    if ((navigator as any).share) {
      (navigator as any).share({
        title: 'Spraklektioner.se',
        text: 'Lär dig engelska, franska och tyska!',
        url: 'https://www.spraklektioner.se/',
      });
    }
  }

  render() {
    return html`
      <app-header></app-header>

      <main>
        <div id="welcomeBar">
          <sl-card id="welcomeCard">
            <div slot="header">
              <h2>${this.message}</h2>
            </div>

            <p>
              För mer information om Spraklektioner.se, besök
              <a href="https://www.franskalektioner.se/">Franska Lektioner</a> och <a href="https://www.engelskalektioner.se/">Engelska Lektioner</a>.
            </p>

            <p id="mainInfo">
              Välkommen till
              <a href="https://www.spraklektioner.se">Spraklektioner.se</a>
              Här kommer så småningom en språknyhet. Ghostmode = ON!
            </p>

            ${'share' in navigator
              ? html`<sl-button slot="footer" variant="primary" @click="${this.share}">Tipsa om Spraklektioner.se!</sl-button>`
              : null}
          </sl-card>

          <sl-card id="infoCard">
            <h2>Relaterade webbplatser och tjänster</h2>

            <ul>
              <li>
                <a href="https://www.engelskalektioner.se/">Engelska Lektioner</a>
              </li>

              <li>
                <a href="https://www.franskalektioner.se/">Franska Lektioner</a>
              </li>

              <li>
                <a href="https://www.excelkursdirekt.eu/">Excelkurs Direkt - Kurser och support i Excel, VBA och automatisering</a>
              </li>
            </ul>
          </sl-card>

          <sl-button href="${resolveRouterPath('about')}" variant="primary">Gå till Om oss</sl-button>
        </div>
      </main>
    `;
  }
}
